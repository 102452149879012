import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CompiereDataGridFilterType,
  CompiereDataGridType,
  DataStore,
  DataStoreKey,
  DataStoreName,
  DataStoreRequest,
} from '@compiere-ws/models/compiere-data-json';
import { DocserverEntry } from '@compiere-ws/models/docserver-entry-json';
import { DocserverWsResponse } from '@compiere-ws/models/docserverWsResponse';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { UploadedFile } from '@iupics-components/models/uploaded-file';
import { AppConfig } from '@iupics-config/app.config';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import {
  MongoSearchQuery,
  MongoSearchQueryOperator,
  MongoSearchQueryOptions,
  MongoSearchQueryPart,
} from '@iupics-manager/models/mongo-search';
import { UserAccount } from '@iupics-manager/models/user-account';
import { environment } from 'environments/environment';
import { cloneDeep } from 'lodash';
import mime from 'mime';
import { Observable, from, of, zip } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { PoService } from '../po/po.service';
@Injectable({
  providedIn: 'root',
})
export class DocServerService {
  private docServerUrl: string;
  private docServerFromWsUrl: string;
  private attachmentInteraction = true;

  constructor(
      private http: ApiService,
      private config: AppConfig,
      private poService: PoService,
      private store: DataStoreService,
      private connectorService: SecurityManagerService
  ) {
    this.docServerUrl = environment.config.backend.docserver.url;
    this.docServerFromWsUrl = this.config.getBackendResource('docserver');
    this.attachmentInteraction = this.config.getConstant('AttachmentInteraction');
  }
  hasPreviewByExt(ext: string) {
    let hasPreview = false;
    if (['pdf', 'xml', 'sh', 'txt', 'csv', 'xlsx'].includes(ext)) {
      hasPreview = true;
    }
    if (!hasPreview) {
      const mimeType = mime.getType(ext);
      if (mimeType && mimeType.includes('image')) {
        hasPreview = true;
      }
    }
    return hasPreview;
  }
  hasPreviewByName(name: string) {
    const nameTolower: string = name.toLowerCase();
    const ext = nameTolower ? (nameTolower as string).toLocaleLowerCase().split('.').pop() : null;
    return this.hasPreviewByExt(ext);
  }
  hasPreview(file: UploadedFile) {
    let hasPreview = false;
    const extFromFileName = file.name ? (file.name as string).toLocaleLowerCase().split('.').pop() : null;
    const ext = file.extension ? (file.extension as string).toLocaleLowerCase().split('.').pop() : null;
    if (['pdf', 'xml'].includes(extFromFileName) || ['pdf', 'xml'].includes(ext)) {
      hasPreview = true;
    }
    return this.hasPreviewByExt(ext) || this.hasPreviewByExt(extFromFileName);
  }
  initBaseSearch(dsKey: DataStoreKey, data: any, adTable_ID: number) {
    const recordId = dsKey.recordId.split(',');
    const filterFromKey = this.createfilterFromKey(dsKey);
    let samples: any[] = [
      {
        ...filterFromKey,
        'META|TABLE_ID': adTable_ID | 0,
        'META|RECORD_ID':
            recordId.length > 1 ? (isNaN(parseInt(recordId[1], 10)) ? recordId[1] : parseInt(recordId[1], 10)) : -1,
      },
    ];
    //On check les colonnes indiquées dans la config comme nécessitant la récup d'attachment
    if (data && data.docFilters) {
      samples = samples.concat(this.createDocFiltersInfo(data.docFilters, dsKey));
    }
    return samples;
  }
  initTags(dsKey: DataStoreKey, data: any, adTable_ID: number, fromNotes = false) {
    const samples = this.initBaseSearch(dsKey, data, adTable_ID);
    const otherTags = samples[0];
    let tags = {};
    const recordId = dsKey.recordId.split(',');
    const key = recordId[0];
    if (key) {
      const userContext = this.connectorService.getIupicsUserContext();
      let sample = cloneDeep(otherTags);
      //add taggedColumns
      if (data && data.taggedColumns) {
        const taggedData = this.createTaggedData(data.taggedColumns, dsKey);
        sample = { ...sample, ...taggedData };
      }
      sample['META|AD_ROLE_ID'] = userContext['#AD_Role_ID'];
      sample['META|ALL|AD_ROLE_ID'] = userContext['#AD_Role_ID'];
      sample['META|AD_CLIENT_ID'] = userContext['#AD_Client_ID'];
      if (fromNotes) {
        sample['META|AD_ORG_ID'] = userContext['#AD_Org_ID'];
      }
      tags = sample;
      const value = recordId.length > 1 ? parseInt(recordId[1], 10) : undefined;
      if (value) {
        tags['META|' + key.toUpperCase()] = value;
      }
    }
    return tags;
  }

  // TODO execute this after 2.9.0 migration: db.documents.find().forEach(function(doc){Object.keys(doc).forEach(function(key){db.documents.update({ _id: doc._id },{ $rename: { [key]:key.toUpperCase() }})})});
  getNbDocuments(searchInformations: any[], combination: MongoSearchQueryOperator): Observable<number> {
    return this.http
        .post<any>(this.docServerUrl + '/getNbDocument', this.createQuery(searchInformations, combination))
        .pipe(
            map((response) => response),
            catchError((_) => of(0))
        );
  }
  getDocument(url: string) {
    return this.connectorService.getAccessToken().pipe(
        switchMap((token) => {
          const headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
            Accept:
                'text/html,application/pdf,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
          });

          return this.http.get(url, { headers, observe: 'response', responseType: 'blob' }).pipe(
              switchMap((response) => {
                return from(response.body.text()).pipe(
                    switchMap((text) => {
                      return of({ response: response, text: text });
                    })
                );
              })
          );
        }),
        catchError((err) => of(null))
    );
  }

  uploadDocument(
      item: File | string,
      fileName: string,
      id?: string,
      type = 'DEFAULT_TYPE',
      additionalInformations?: any
  ): Observable<DocserverEntry> {
    const formData = new FormData();
    if (typeof item === 'string') {
      formData.append('url', item);
      formData.append('provider', 'external');
    } else {
      formData.append('file', item);
    }
    formData.append('fileName', fileName);
    formData.append('id', id);
    formData.append('type', type);
    if (additionalInformations) {
      for (const key in additionalInformations) {
        const element = additionalInformations[key];
        formData.append(key, element);
      }
    }
    return this.http.post<DocserverEntry>(this.docServerUrl + '/upload', formData);
  }

  searchDocuments(args?: any, attachmentInteraction = false): Observable<any> {
    if (args !== undefined) {
      const attachmentRequest: DataStoreRequest = {
        windowId: undefined,
        compiereRequest: {
          startRow: 0,
          endRow: -1,
          tableName: 'AD_Attachment',
          windowType: CompiereDataGridType.TABLE,
          filterModel: {
            AD_Table_ID: {
              filterType: CompiereDataGridFilterType.SET,
              values: [args['META|TABLE_ID']],
              operators: [OperatorFilterType.EQUALS],
            },
            Record_ID: {
              filterType: CompiereDataGridFilterType.SET,
              values: [args['META|RECORD_ID']],
              operators: [OperatorFilterType.EQUALS],
            },
          },
        },
      };

      return zip(
          attachmentInteraction && this.attachmentInteraction ? this.store.getDataGrid(attachmentRequest) : of(null),
          this.http.get<any>(this.docServerUrl + '/search' + this.buildQueryParams(args))
      ).pipe(
          map(([dataGridResponse, docServerResponse]) => {
            return docServerResponse.hits.map((attachment) => {
              if (dataGridResponse) {
                const findElem = dataGridResponse.data.find(
                    (elem) => elem['Title'] === attachment['docId'] || elem['DocServerID'] === attachment['docId']
                );
                if (findElem) {
                  attachment['attachment_ID'] = findElem['AD_Attachment_ID'];
                }
              }
              return attachment;
            });
          })
      );
    } else {
      return null;
    }
  }

  createQuery(
      searchInformations: any[],
      combination: string = MongoSearchQueryOperator.OR,
      tagFilters: any = null,
      hasOrgSecurity: boolean = true
  ): MongoSearchQuery {
    const userContext = this.connectorService.getIupicsUserContext();
    const userAcount: UserAccount = this.connectorService.getIupicsUserAccount();
    const searchInfoQueryGroup = MongoSearchQueryPart.createQueryGroup(combination);
    searchInformations.forEach((searchInformation) => {
      const subQueryGroup = this.addQueryGroup(searchInformation);
      if (subQueryGroup.expressions.length > 0) searchInfoQueryGroup.addExpression(subQueryGroup);
    });
    const mainQueryGroup = MongoSearchQueryPart.createQueryGroup();
    if (searchInfoQueryGroup.expressions.length > 0) mainQueryGroup.addExpression(searchInfoQueryGroup);
    if (tagFilters) {
      const tagFiltersQueryGroup = this.addQueryGroup(tagFilters);
      if (tagFiltersQueryGroup.expressions.length > 0) mainQueryGroup.addExpression(tagFiltersQueryGroup);
    }

    return new MongoSearchQuery(mainQueryGroup, userContext, userAcount.current_role.orgAccess, hasOrgSecurity);
  }

  addQueryGroup(filters) {
    const queryGroup = MongoSearchQueryPart.createQueryGroup();
    Object.keys(filters).forEach((key) => {
      if (filters[key] !== undefined && filters[key] !== null) {
        const operator: MongoSearchQueryOperator =
            filters[key] === 'true' || filters[key] === 'false'
                ? MongoSearchQueryOperator.EXIST
                : key.startsWith('META|ALL|') ||
                key.startsWith('META|OCR_DATA') ||
                key.startsWith('META|SUMMARY') ||
                key.startsWith('META|FILENAME')
                    ? MongoSearchQueryOperator.REGEX
                    : MongoSearchQueryOperator.EQUALS;
        queryGroup.addExpression(MongoSearchQueryPart.createQueryPart(key, filters[key], operator));
      }
    });
    return queryGroup;
  }

  advancedSearchDocuments(
      searchQuery: MongoSearchQuery,
      options: MongoSearchQueryOptions,
      tab_id?: number,
      table_id?: number,
      record_id?: number
  ): Observable<DocserverWsResponse> {
    if (searchQuery !== undefined) {
      let attachmentRequest: DataStoreRequest = null;
      if (table_id && record_id && options.attachmentInteraction) {
        attachmentRequest = {
          windowId: undefined,
          compiereRequest: {
            startRow: 0,
            endRow: -1,
            tableName: 'AD_Attachment',
            windowType: CompiereDataGridType.TABLE,
            filterModel: {
              AD_Table_ID: {
                filterType: CompiereDataGridFilterType.SET,
                values: [table_id],
                operators: [OperatorFilterType.EQUALS],
              },
              Record_ID: {
                filterType: CompiereDataGridFilterType.SET,
                values: [record_id],
                operators: [OperatorFilterType.EQUALS],
              },
            },
          },
        };
      }
      return zip(
          options.attachmentInteraction && this.attachmentInteraction && attachmentRequest
              ? this.store.getDataGrid(attachmentRequest)
              : of(null),
          this.http.post<any>(
              this.docServerFromWsUrl +
              this.buildQueryParams({ limit: options.limit, start: options.start, tabId: tab_id }),
              searchQuery
          )
      ).pipe(
          map(([dataGridResponse, docServerResponse]) => {
            return {
              entries: docServerResponse.documents.map((attachment) => {
                if (dataGridResponse) {
                  const findElem = dataGridResponse.data.find(
                      (elem) => elem['Title'] === attachment['docId'] || elem['DocServerID'] === attachment['docId']
                  );
                  if (findElem) {
                    attachment['attachment_ID'] = findElem['AD_Attachment_ID'];
                  }
                }
                return attachment;
              }),
              displayedTags: docServerResponse.displayedTags,
            };
          })
      );
    } else {
      return null;
    }
  }

  createfilterFromKey(dsKey: DataStoreKey) {
    const keyMap: Map<string, number> = this.store.extractRecordInfoFromDsKey(dsKey);
    if (keyMap.size > 0) {
      const filter = {};
      keyMap.forEach((value, key) => {
        filter['META|' + key.toUpperCase()] = value + '';
      });
      return filter;
    } else {
      return null;
    }
  }

  createDocFiltersInfo(docFilters: String, dsKey: DataStoreKey): any[] {
    const filters = [];
    // #START CUSTO-SAMVAZ
    // const filterFromKey = this.createfilterFromKey(dsKey);
    // const keyMap: Map<string, number> = this.store.extractRecordInfoFromDsKey(dsKey);
    // if (docFilters) {
    //   const oldStore: DataStore = this.store.getStore(dsKey, DataStoreName.OLD) as DataStore;
    //   if (oldStore && oldStore.data) {
    //     const columns: string[] = docFilters.split(',');
    //     columns.forEach((col) => {
    //       let trimmedCol = col.trim();
    //       let restricted = true;
    //       if (trimmedCol !== '*' && trimmedCol.endsWith('*')) {
    //         trimmedCol = trimmedCol.substring(0, trimmedCol.length - 1);
    //         restricted = false;
    //       }
    //       let value = oldStore.data[trimmedCol];
    //       if (
    //         oldStore.data[trimmedCol] &&
    //         oldStore.data[trimmedCol].id !== null &&
    //         oldStore.data[trimmedCol].id !== undefined
    //       ) {
    //         value = oldStore.data[trimmedCol].id;
    //       }
    //       if (trimmedCol && trimmedCol.length > 0) {
    //         let filter = {};
    //         if (trimmedCol === '*') {
    //           if (columns.length === 1) {
    //             // if no others filters exist we can add this Global filter search
    //             if (keyMap.size > 1) {
    //               filters.push(filterFromKey);
    //             } else {
    //               // simple key
    //               keyMap.forEach((v, k) => {
    //                 filter['META|ALL|' + k.toUpperCase()] = '.*' + v + '.*';
    //               });

    //               filters.push(filter);
    //               filter = cloneDeep(filterFromKey);
    //               filters.push(filter);
    //             }
    //           }
    //         } else {
    //           // linked table col
    //           if (value !== undefined && value !== null) {
    //             filter = restricted ? cloneDeep(filterFromKey) : {};
    //             filter['META|ALL|' + trimmedCol.toUpperCase()] = '.*' + value + '.*';
    //             filters.push(filter);
    //             filter = restricted ? cloneDeep(filterFromKey) : {};
    //             filter['META|' + trimmedCol.toUpperCase()] = value;
    //           } else {
    //             filter = restricted ? cloneDeep(filterFromKey) : {};
    //             filter['META|' + trimmedCol.toUpperCase()] = 'true';
    //           }
    //           if (restricted) {
    //             filter['META|TYPE'] = 'ATTACHEMENT';
    //           }
    //           filters.push(filter);
    //         }
    //       }
    //     });
    //   }
    // }
    // #END CUSTO-SAMVAZ
    return filters;
  }

  createTaggedData(taggedColumns: String, dsKey: DataStoreKey): any {
    const filter = {};
    if (taggedColumns) {
      const oldStore: DataStore = this.store.getStore(dsKey, DataStoreName.OLD) as DataStore;
      if (oldStore && oldStore.data) {
        const columns: string[] = taggedColumns.split(',');
        columns.forEach((col) => {
          if (col) {
            if (Object.keys(oldStore.data).find((key) => key.toLowerCase() === col.toLowerCase())) {
              let id = oldStore.data[col];
              let displayValue: string = null;
              if (oldStore.data[col] && oldStore.data[col].id !== undefined && oldStore.data[col].id !== null) {
                id = oldStore.data[col].id;
                displayValue = oldStore.data[col].displayValue;
              }
              if (id !== null) {
                filter['META|' + col.toUpperCase()] = id;
                if (displayValue != null && displayValue.trim().length > 0) {
                  filter['META|' + col.toUpperCase() + '$'] = displayValue;
                }
              }
            }
          }
        });
      }
    }
    return filter;
  }

  downloadDocument(url: string) {
    return this.http.get(this.getUrlDownload(url), { responseType: 'blob' as 'json', observe: 'response' });
  }

  getUrlDownload(url: string) {
    return this.docServerUrl + url;
  }

  getUrlPreview(url: string) {
    return this.docServerUrl + url.replace('/download/', '/preview/');
  }

  deleteDocument(file: any, isAttachment?: boolean): Observable<any> {
    const obs = this.http.delete<any>(this.docServerUrl + '/document/' + file.docId, {
      responseType: 'text' as 'json',
    });
    if (this.attachmentInteraction && isAttachment && file.attachment_ID) {
      return obs.pipe(
          tap((docServerData) => {
            const s = this.poService.delete('AD_Attachment', file.attachment_ID).subscribe(() => s.unsubscribe());
          })
      );
    } else {
      return obs;
    }
  }

  private buildQueryParams(args: any): string {
    if (args !== undefined) {
      let query = '';
      Object.keys(args).forEach((key, index) => {
        if (args[key] !== undefined && args[key] !== null) {
          query +=
              (index === 0 ? '?' : '') +
              encodeURI(key) +
              '=' +
              args[key] +
              (index + 1 !== Object.keys(args).length ? '&' : '');
        }
      });
      return query;
    } else {
      return '';
    }
  }
}
