import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  inject,
} from '@angular/core';
import { CompiereDataFieldType, DataStore, DataStoreStatus } from '@compiere-ws/models/compiere-data-json';
import { PoService } from '@compiere-ws/services/po/po.service';
import { CalendarConfig } from '@iupics-components/overrided/prime-calendar/prime-calendar.component';
import EditViewUiComponent from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { EditViewUtils } from '@iupics-components/standard/layouts/edit-view-ui/utils/edit-view.utils';
import { CacheManagerService } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { IupicsComponentType, IupicsContextMenuComponent } from '@web-desktop/models/iupics-context-menu';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { cloneDeep, has, isNil } from 'lodash';
import * as moment from 'moment';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DynamicComponent } from './dynamic-component';
import { Global } from './global-var';
import { IupicsEvent } from './iupics-event';

@Directive()
export abstract class AbstractDataContainer
    extends AbstractDynamicComponent
    implements OnInit, OnDestroy, IupicsContextMenuComponent, AfterViewInit
{
  public elementRef = inject(ElementRef<HTMLElement>);
  protected connectorService = inject(SecurityManagerService);
  public cmService = inject(ContextMenuService);
  public store = inject(DataStoreService);
  public uiCreatorService = inject(UICreatorService);
  protected renderer = inject(Renderer2);
  protected po = inject(PoService);
  protected cacheService = inject(CacheManagerService);

  @Input()
  tooltip: string;
  @Input()
  label: string;
  @Input()
  isReadOnly: boolean;
  @Input()
  isStandalone = false;
  @Input()
  displayImage = false;
  @Input()
  isContextMenuDisabled = false;
  @Input()
  locale: string;
  @Output()
  fieldValueModified: EventEmitter<DataStore | any> = new EventEmitter();
  @Output()
  afterViewInitEmitter: EventEmitter<DataStore> = new EventEmitter();
  @Input()
  isLabelDisplay = true;

  @Input() isTriState = false;
  displayCss: string;
  mandatoryCss: string;
  backgroundColorCss = 'transparent';
  isDateField = false;
  isSwitchField = false;
  isAutocompleteField = false;
  isMoneyField = false;
  isImageField = false;
  isAddressField = false;
  multiple = false;
  currency = '';
  componentType = null; // IupicsComponentType.FIELDCALLOUT;
  showValuePrefPanel = false;
  protected _dataStored: DataStore;
  get dataStored(): DataStore {
    return this._dataStored;
  }
  set dataStored(dataStored: DataStore) {
    this._dataStored = dataStored;
    if (dataStored) {
      if (!this.newRecordKey || this.newRecordKey.recordId !== dataStored.key.recordId) {
        this.newRecordKey = dataStored.key;
        this.isSetDefaultValue = this.fieldType === CompiereDataFieldType.FIELD;
      }
    }
    /*mettre à jour le datastore */
  }
  dataChanged$: Subscription;
  dataConflicts: any;
  initContextFieldSub: any;
  isSetDefaultValue = false;
  newRecordKey;
  defaultValue;
  logicContextField: LogicContext = new LogicContext();
  isContextMenu = false;
  isZoom = false;
  isAccordion = false;
  itemData: DynamicComponent;
  hasConflict = false;
  conflictedData: any;
  fieldType: CompiereDataFieldType = CompiereDataFieldType.FIELD;
  editViewParent: EditViewUiComponent;
  isSetMandatoryValue = false;
  fixCurrency = false;

  private dv$ = new Subject<any>();
  isForInfoWindow = false;
  changeFieldValueSub: Subscription;
  @ViewChild('opValuePref') valuePrefoverlayPanel: OverlayPanel;
  @HostBinding('class') @Input() cssGrid: string;

  @Input() calendarConfig: CalendarConfig = { todayMode: false };
  @Output() calendarConfigChange = new EventEmitter<CalendarConfig>();

  //#region apiz-grid
  lockFilterPanel = () => {};
  unlockFilterPanel = () => {};
  //#endregion

  ngOnInit() {
    Global.startPerf(this);
    /*Permet de notifier au parent qu'il a un nouveau datacontainer en tant qu'enfant et de récupérer */
    if (this.DOMParentComponent) {
      let parent = this.DOMParentComponent;
      while (parent && !(parent instanceof EditViewUiComponent)) {
        parent = parent.DOMParentComponent;
      }
      if (parent instanceof EditViewUiComponent) {
        this.editViewParent = parent;
      }
    }
    if (this.parentTab) {
      // on empêche les valeurs par défaut de se déclencher pour les fenêtres standard
      this.isSetDefaultValue = this.fieldType === CompiereDataFieldType.FIELD;
      this.setNewData(this.parentTab.dataStored, true);
      this.parentTab.addDataContainerToEditTab(this);
    }
    if (this.parentProcess) {
      this.container = this.parentProcess;
      this.setNewData(this.parentProcess.dataStore, true);
      this.parentProcess.addDataContainerToEditTab(this);
    }
    if (this.DOMParentComponent?.windowType === IupicsMenuType.FORM) {
      this.setNewData(this.dataStored, true);
    }
    this.initLabel();
    this.initTooltip();
    if (this.data && this.data.isLabelDisplay !== undefined && this.data.isLabelDisplay !== null) {
      this.isLabelDisplay = this.data.isLabelDisplay;
    }
    // set old parsed
    this.initContextField();
    // && !this.data.isParam
    if (this.data && this.data.isParam && this.itemData && this.itemData.formId) {
      this.componentType = IupicsComponentType.FIELDFORM;
    } else if (this.data && (!this.isAutocompleteField || !this.data.detailZoom)) {
      if (
          this.connectorService.getIupicsUserAccount() &&
          (this.connectorService.getIupicsUserAccount().current_role.calloutLevel === 'None' ||
              this.connectorService.getIupicsUserAccount().current_role.calloutLevel === '')
      ) {
        this.componentType = null;
        if (this.isAddressField) {
          this.componentType = IupicsComponentType.ADDRESSFIELD;
        }
      } else {
        if (this.isAddressField) {
          this.componentType = IupicsComponentType.ADDRESSFIELD;
        }
      }
    } else {
      this.componentType = IupicsComponentType.FIELDZOOM;
    }

    // Alimentation de la locale pour les champs
    if (!this.locale) {
      this.locale = this.connectorService.getIupicsDefaultLanguage().iso_code.replace(/_/g, '-');
    } else {
      this.locale = this.locale.replace(/_/g, '-');
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.dataChanged$ && this.dataStored) {
      this.dataChanged$.unsubscribe();
      this.dataConflicts.unsubscribe();
      this.initContextFieldSub.unsubscribe();
    }
  }

  initLabel() {
    if (this.data && (this.data.label || this.data.poLabel)) {
      const ctx = this.dataStored ? this.getCurrentContext() : null;
      if (
          ctx &&
          ctx['IsSOTrx'] !== null &&
          ctx['IsSOTrx'] !== undefined &&
          ctx['IsSOTrx'] === 'N' &&
          this.data.poLabel
      ) {
        this.label = this.data.poLabel;
      } else {
        this.label = this.data.label;
      }
    }
  }

  initTooltip(data?: any) {
    const dataToFormat = this.data ?? data;
    if (!dataToFormat) {
      return;
    }

    const trimNull = (str: string) => str.replace(/^\bnull\b/i, '').trim();
    let description = trimNull(dataToFormat?.description ?? dataToFormat?.data?.description ?? '');
    let help = trimNull(dataToFormat?.help ?? dataToFormat?.data?.help ?? '');
    if (!description && !help) {
      return;
    }

    this.tooltip = ``;
    if (description.length > 0) {
      this.tooltip += `<span class="tooltip-description">${description}</span>`;
    }
    if (help.length > 0 && description !== help) {
      this.tooltip += `<span class="tooltip-help">${help}</span>`;
    }
  }

  onMouseDown(event: MouseEvent) {
    if (event.buttons === 2) {
      this.isContextMenu = true;
    }
    event.stopPropagation();
  }

  onContextMenu(event: MouseEvent) {
    if (!this.isContextMenuDisabled) {
      if (!this.isZoom) {
        this.cmService.showContextMenu(this, this.handleEvent.bind(this), event);
      }
    }
    event.preventDefault();
  }

  handleEvent(event: any) {
    switch (event.item.id) {
      case 'valuePreference':
        const eventCloned = cloneDeep(event.originalEvent);
        eventCloned.target = this.elementRef.nativeElement;
        this.toggleValuePreferencePanel(eventCloned);
        break;
      case 'zoom':
        this.zoomAcross();
        break;
      case 'refresh':
        this.search(event, true);
        break;
      case 'new':
        this.showFormPanel(this.itemData['formId']);
        break;
    }
  }
  search(event, forceRefresh = false) {}
  zoomAcross() {}
  showFormPanel(formId) {}
  toggleValuePreferencePanel(event) {
    if (this.showValuePrefPanel && this.valuePrefoverlayPanel) {
      this.showValuePrefPanel = !this.valuePrefoverlayPanel.overlayVisible;
    } else {
      this.showValuePrefPanel = !this.showValuePrefPanel;
    }

    setTimeout(() => {
      let i = 0;
      if (this.showValuePrefPanel) {
        while (!this.valuePrefoverlayPanel && i < 5000) {
          i++;
        }
        if (i < 5000) {
          this.valuePrefoverlayPanel.toggle(event);
        } else {
          this.showValuePrefPanel = false;
        }
      }
    }, 0);
  }
  isFieldDisplay(dataStored: DataStore, changedColumns?: any): Observable<boolean> {
    if (this.data.displayLogic) {
      const dataMapToTest = this.getCurrentContext(dataStored, false);
      const hasContextVariable = this.hasContextLogicVariable(LogicContextProperty.DISPLAY);
      let hasContextChanged = true;
      if (hasContextVariable && changedColumns) {
        hasContextChanged = this.verifyContextLogic(Object.keys(changedColumns), LogicContextProperty.DISPLAY);
      }
      if (hasContextChanged || this.getCurrentLogicContextValue(LogicContextProperty.DISPLAY) === undefined) {
        if (this.data.displayLogic && this.data.displayLogic.trim().toLowerCase().startsWith('@sql=')) {
          return this.uiCreatorService
              .getDBSelect(
                  LogicEvaluator.replaceVariables(
                      this.data.displayLogic.slice(5),
                      this.connectorService.getIupicsUserContext(),
                      this.getCurrentContext(dataStored, false)
                  ),
                  [],
                  []
              )
              .pipe(
                  map((data) => {
                    this.updateCurrentLogicContextValue(LogicContextProperty.DISPLAY, data.length > 0);
                    return data.length > 0;
                  })
              );
        } else {
          this.updateCurrentLogicContextValue(
              LogicContextProperty.DISPLAY,
              LogicEvaluator.evaluateLogic(dataMapToTest, this.data.displayLogic)
          );
          return of(this.getCurrentLogicContextValue(LogicContextProperty.DISPLAY));
        }
      } else {
        return of(this.getCurrentLogicContextValue(LogicContextProperty.DISPLAY));
      }
    } else {
      return of(true);
    }
  }

  isFieldReadOnly(dataStored: DataStore, changedColumns: any): boolean {
    if (dataStored) {
      // * utilisé pour debug les problèmes en read-only
      if (
          (this.parentProcess === undefined || this.parentProcess === null) &&
          (this.container === undefined || this.container === null || this.container.formId === undefined)
      ) {
        if (
            this.data.isAlwaysUpdatable !== undefined &&
            this.data.isAlwaysUpdatable !== null &&
            this.data.isAlwaysUpdatable === true
        ) {
          return false;
        }
        if (this.parentTab?.isReadOnly) {
          return true;
        }
        if (this.data.columnName === 'IsActive') {
          return false;
        }
        if (
            dataStored.data.IsActive !== undefined &&
            dataStored.data.IsActive !== null &&
            dataStored.data.IsActive === 'N' &&
            !this.isAccordion
        ) {
          return true;
        }
        if (
            this.data.columnName === 'Posted' ||
            (this.data.columnName === 'Record_ID' && this.data.componentName === 'ButtonUiComponent')
        ) {
          return false;
        }
        if (this.data.columnName === 'Processing' || this.data.columnName === 'GenerateTo') {
          return false;
        }
        // Chez les scouts une fenetre avec processed true est readonly
        const fullCtx = this.getCurrentContext(dataStored, false);
        if (!this.isAccordion && fullCtx.Processed && fullCtx.Processed === 'Y') {
          return true;
        }
        if (this.data.isReadOnly !== undefined && this.data.isReadOnly !== null && this.data.isReadOnly === true) {
          return this.data.isReadOnly;
        }
        // Gestion de la readonly lors de la modification
        if (this.data.isUpdateable === false && dataStored && dataStored.status !== DataStoreStatus.NEWRECORD) {
          return true;
        }
        if (!this.canUpdate(dataStored)) {
          return true;
        }
        if (this.data.readOnlyLogic) {
          const hasContextVariable = this.hasContextLogicVariable(LogicContextProperty.READONLY);
          let hasContextChanged = true;
          if (hasContextVariable && changedColumns) {
            hasContextChanged = this.verifyContextLogic(Object.keys(changedColumns), LogicContextProperty.READONLY);
          }
          if (hasContextChanged || this.getCurrentLogicContextValue(LogicContextProperty.READONLY) === undefined) {
            if (this.data.readOnlyLogic && this.data.readOnlyLogic.trim().toLowerCase().startsWith('@sql=')) {
              this.uiCreatorService
                  .getDBSelect(
                      LogicEvaluator.replaceVariables(
                          this.data.readOnlyLogic.slice(5),
                          this.connectorService.getIupicsUserContext(),
                          this.getCurrentContext(dataStored, false)
                      ),
                      [],
                      []
                  )
                  .subscribe((data) => {
                    if (data.length > 0) {
                      this.isReadOnly = true;
                    } else {
                      this.isReadOnly = false;
                    }
                    this.updateCurrentLogicContextValue(LogicContextProperty.READONLY, this.isReadOnly);
                  });
            } else {
              let isReadOnly = false;
              if (
                  this.data.readOnlyLogic &&
                  LogicEvaluator.evaluateLogic(this.getCurrentContext(dataStored, false), this.data.readOnlyLogic)
              ) {
                isReadOnly = true;
              }
              this.updateCurrentLogicContextValue(LogicContextProperty.READONLY, isReadOnly);
              return isReadOnly;
            }
          } else {
            this.isReadOnly = this.getCurrentLogicContextValue(LogicContextProperty.READONLY);
            return this.isReadOnly;
          }
        }
      } else if (
          this.data.readOnlyLogic &&
          LogicEvaluator.evaluateLogic(this.getCurrentContext(dataStored, false), this.data.readOnlyLogic)
      ) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  canUpdate(dataStore: DataStore) {
    const dataStored = dataStore ? dataStore : this.dataStored;
    let retValue = true;
    const userLevel = this.connectorService.getIupicsUserContext()['#User_Level'] as string;
    let clientID = -1;
    let orgID = -1;
    if (dataStored.data && dataStored.data['AD_Client_ID'] !== undefined && dataStored.data['AD_Client_ID'] !== null) {
      // if (dataStored.data['AD_Client_ID'] === null) {
      //   return false;
      // } else
      if (dataStored.data['AD_Client_ID'].id !== undefined && dataStored.data['AD_Client_ID'].id !== null) {
        clientID = isNaN(parseInt(dataStored.data['AD_Client_ID'].id, 10))
            ? -1
            : parseInt(dataStored.data['AD_Client_ID'].id, 10);
      } else {
        clientID = isNaN(parseInt(dataStored.data['AD_Client_ID'], 10))
            ? -1
            : parseInt(dataStored.data['AD_Client_ID'], 10);
      }
    }
    if (dataStored.data && dataStored.data['AD_Org_ID'] !== undefined && dataStored.data['AD_Org_ID'] !== null) {
      if (dataStored.data['AD_Org_ID'].id !== undefined && dataStored.data['AD_Org_ID'].id !== null) {
        orgID = isNaN(parseInt(dataStored.data['AD_Org_ID'].id, 10))
            ? -1
            : parseInt(dataStored.data['AD_Org_ID'].id, 10);
      } else {
        orgID = isNaN(parseInt(dataStored.data['AD_Org_ID'], 10)) ? -1 : parseInt(dataStored.data['AD_Org_ID'], 10);
      }
    }
    if (clientID === -1 || orgID === -1) {
      return true;
    }
    if (clientID === 0 && orgID === 0 && userLevel.indexOf('S') === -1) {
      retValue = false;
    } else if (clientID !== 0 && orgID === 0 && userLevel.indexOf('C') === -1) {
      retValue = false;
    } else if (clientID !== 0 && orgID !== 0 && userLevel.indexOf('O') === -1) {
      retValue = false;
    }
    return retValue;
  }

  readOnlyNumberComponent() {}
  _parseFloat(value: string) {}

  setFieldMandatory() {
    if (this.data && this.data.mandatoryLogic) {
      this.data.isMandatory = LogicEvaluator.evaluateLogic(
          this.getCurrentContext(this.dataStored, false),
          this.data.mandatoryLogic
      );
    }
    if (this.data && this.data.isMandatory) {
      this.mandatoryCss = ' iu-field-mandatory';
    } else {
      this.mandatoryCss = ' iu-field';
    }
    if (
        this.DOMParentComponent !== undefined &&
        (this.DOMParentComponent.DOMParentComponent !== undefined &&
            (<any>this.DOMParentComponent.DOMParentComponent).isAccordion) === true
    ) {
      (<any>this.DOMParentComponent.DOMParentComponent).setMandatory();
    }
  }

  getfieldValue() {
    return this.fieldValue;
  }

  dataChange(value: any) {
    if (this.isStandalone) {
      if (this.dataStored) {
        this.updateCurrentContext(value);
      }
      this.fieldValueModified.emit(value);
      this.setFieldMandatory();
    } else if (this.data) {
      this.updateStore(value);
      this.fieldValueModified.emit(this.dataStored);
    }
  }

  calloutChange(value: any) {
    this.dataChange(value);
  }
  /**
   * Modifie la visibilité du champ
   * @param dataStored
   */
  updateDisplay(dataStored: DataStore, changedColumns?: any) {
    const datastore = dataStored ? dataStored : this.dataStored;
    this.isFieldDisplay(datastore, changedColumns).subscribe((displayed) => {
      if (this.data.componentName === 'ButtonUiComponent') {
        //check if the current process already exists
        const processes: any[] = this.store.processesDisplay.filter((process: any) => {
          if (process['fieldID'] == this.data.fieldId && process['tabID'] == this.data.tabId) return true;
          else return false;
        });

        if (processes.length == 0) {
          this.store.processesDisplay.push({
            fieldID: this.data.fieldId,
            displayed: displayed,
            readOnly: false,
            tabID: this.data.tabId,
          });
          this.store.processCheck.next(true);
        } else {
          processes[0]['displayed'] = displayed;
          this.store.processCheck.next(true);
        }
      }

      if (displayed) {
        this.renderer.removeClass(this.elementRef.nativeElement, 'dataHidden');
        this.displayCss = 'inline';
      } else {
        this.renderer.addClass(this.elementRef.nativeElement, 'dataHidden');
        this.displayCss = 'none';
      }
      // trigger for the accordion
      if (
          this.DOMParentComponent !== undefined &&
          (this.DOMParentComponent.DOMParentComponent !== undefined &&
              (<any>this.DOMParentComponent.DOMParentComponent).isAccordion) === true
      ) {
        (<any>this.DOMParentComponent.DOMParentComponent).updateDisplay(datastore);
      }
    });
  }
  /**
   * Modifie la readonly du champ
   * @param dataStored
   * @param changedColumns les informations des champs modifiés
   */
  updateReadOnly(dataStored: DataStore, changedColumns?: any) {
    const datastore = dataStored ? dataStored : this.dataStored;
    // Check if field is Read Only
    if (this.isFieldReadOnly(datastore, changedColumns)) {
      this.isReadOnly = true;
      if (this.data && this.data.numberType) {
        this.readOnlyNumberComponent();
      }
    } else {
      this.isReadOnly = false;
    }

    if (this.data.componentName === 'ButtonUiComponent') {
      //check if the current process already exists
      const processes: any[] = this.store.processesDisplay.filter((process: any) => {
        if (process['fieldID'] == this.data.fieldId && process['tabID'] == this.data.tabId) return true;
        else return false;
      });

      if (processes.length == 0) {
        this.store.processesDisplay.push({
          fieldID: this.data.fieldId,
          displayed: false,
          readOnly: this.isReadOnly,
          tabID: this.data.tabId,
        });
        this.store.processCheck.next(true);
      } else {
        processes[0]['readOnly'] = this.isReadOnly;
        this.store.processCheck.next(true);
      }
    }

    // trigger for the accordion
    if (
        this.DOMParentComponent !== undefined &&
        (this.DOMParentComponent.DOMParentComponent !== undefined &&
            (<any>this.DOMParentComponent.DOMParentComponent).isAccordion) === true
    ) {
      (<any>this.DOMParentComponent.DOMParentComponent).updateReadOnly(datastore);
    }
  }
  /**
   * check si le champ doit être affiché ou readonly
   * @param dataStored
   */
  checkReadAndDisplay(dataStored: DataStore) {
    this.updateDisplay(dataStored);
    this.updateReadOnly(dataStored);
  }
  setNewData(dataStored: DataStore, isInit = false) {
    const currentRecordId = this.dataStored && this.dataStored.key ? this.dataStored.key.recordId : null;
    const newRecordId = dataStored && dataStored.key ? dataStored.key.recordId : null;
    const recordChanged = isInit ? true : currentRecordId !== newRecordId;
    if (this.dataStored) {
      this.dataChanged$?.unsubscribe();
      this.dataConflicts?.unsubscribe();
      this.fieldValue = null;
    }
    if (this.container?.activeTab?.ctx?.[this.data?.columnName]) {
      const zoomCtxData = {};
      zoomCtxData[this.data?.columnName] = this.container.activeTab.ctx[this.data.columnName];
      dataStored.addContextData(zoomCtxData);
      delete this.container.activeTab.ctx[this.data.columnName];
    }
    this.dataStored = dataStored;
    this.initContextField();
    if (this.dataStored) {
      this.initLabel();
      this.dataChanged$ = dataStored.dataChange.subscribe(
          (dataChange: {
            dataModified: any;
            bypassValidation: boolean;
            calloutStack: any;
            fromDefaultValue: boolean;
          }) => {
            const dataChanged = dataChange.dataModified;
            // * changement du symbole de la currency
            if (this.isMoneyField && !this.fixCurrency) {
              this.updateCurrency(dataChange.dataModified);
            }

            const bypassValidation = dataChange.bypassValidation !== undefined ? dataChange.bypassValidation : false;
            if (dataChanged[this.data.columnName] !== undefined) {
              this.changeFieldValue(
                  dataStored,
                  true,
                  dataChange.calloutStack,
                  dataChange.fromDefaultValue,
                  dataStored.data[this.data.columnName]
              );
              this.hasConflict = false;
            }
            if (dataChanged[this.data.columnName] === undefined || Object.keys(dataChanged).length > 1) {
              // Reparsing of defaultvalue and validation
              if (
                  dataChange.fromDefaultValue &&
                  this.logicContextField &&
                  this.logicContextField.defaultvalue &&
                  this.verifyContextLogic(Object.keys(dataChanged), LogicContextProperty.DEFAULT_VALUE)
              ) {
                // if still in defaultvalue processing retry parsing with new validation variable
                this.parseDefaultValue(dataStored);
              } else if (this.logicContextField && this.logicContextField.validation && !bypassValidation) {
                if (this.verifyContextLogic(Object.keys(dataChanged), LogicContextProperty.VALIDATION)) {
                  const entityId =
                      this.fieldType === CompiereDataFieldType.FIELD ? this.data.fieldId : this.data.columnId;
                  const id = this.extractId(this.dataStored.data[this.data.columnName]);

                  if (id !== null) {
                    this.subscriptions.push(
                        this.store
                            .getAutocompleteDataById(
                                this.fieldType,
                                entityId,
                                id,
                                LogicEvaluator.replaceVariables(
                                    this.data.validationCode,
                                    this.connectorService.getIupicsUserContext(),
                                    this.getCurrentContext()
                                )
                            )
                            .subscribe((dataWs) => {
                              if (dataWs.length <= 0) {
                                const dataForUpdate = {};
                                dataForUpdate[this.data.columnName] = null;
                                this.store.syncDataChanges(this.dataStored, dataForUpdate, true);
                              }
                            })
                    );
                  } else if (dataChange.fromDefaultValue) {
                    // if still in defaultvalue processing retry parsing with new validation variable
                    this.parseDefaultValue(dataStored);
                  }
                }
              }
            }
            this.updateUI(dataChanged);
          }
      );
      this.dataConflicts = dataStored.dataConflict.subscribe((dataConflicted) => {
        this.hasConflict = dataConflicted.dataConflict[this.data.columnName] !== undefined;
        this.conflictedData = dataConflicted.dataConflict[this.data.columnName];
      });
      if (this.initContextFieldSub) {
        this.initContextFieldSub.unsubscribe();
      }
      this.initContextFieldSub = dataStored.initContextField.subscribe(() => {
        this.initContextField();
      });
      if (this.isMoneyField) {
        this.initCurrency(dataStored);
      }
      // on set les valeurs qui sont spécifiées dans l'url
      if (
          recordChanged &&
          this.dataStored.status === DataStoreStatus.NEWRECORD &&
          !this.dataStored.isCopied &&
          !this.isSetDefaultValue
      ) {
        // on check sila valeur a déjà été setté par un callout avant le subscribe au datastore.datachange
        const valueFromStore = this.dataStored.data[this.data.columnName];
        if (
            valueFromStore &&
            (this.isInUniversalFilter ||
                (this.container && this.container.windowType !== IupicsMenuType.FORM && !this.parentProcess))
        ) {
          this.changeFieldValue(dataStored, true, [this.data.columnName], false, valueFromStore);
          this.updateUI();
        } else {
          this.parseDefaultValue(dataStored);
        }
      } else {
        this.changeFieldValue(dataStored);
        this.updateUI();
        if ((this.isAddressField || this.isAutocompleteField) && this.isZoom) {
          this.refreshZoomInfo();
        }
      }
      // #168403
      if (this.isImageField) {
        this.displayImage = this.fieldValue ? true : false;
      }
    } else {
      if (this.data && this.data.numberType) {
        this.fieldValue = 0;
      } else {
        this.fieldValue = null;
      }
      this.updateUI();
    }
    //#START CUSTO-SAMVAZ
    if (!isInit && dataStored.status === DataStoreStatus.NEWRECORD) {
      this.checkFocus();
    }
  }
  checkFocus() {}
  //#END CUSTO-SAMVAZ
  private waitDefaultValueParsing(dataStored: DataStore) {
    this.isSetDefaultValue = false;
    const sub = this.dv$.subscribe((defaultValue) => {
      if (defaultValue !== undefined && defaultValue !== null) {
        this.defaultValue = defaultValue;
        if (this.isDateField) {
          let newDate;
          const regexp = new RegExp('[0-9]{4}[-][0-9]{2}[-][0-9]{2}');
          if (regexp.test(this.defaultValue)) {
            newDate = new Date(this.defaultValue);
          } else {
            newDate = new Date();
            newDate.setTime(parseInt(this.defaultValue, 0));
          }
          this.defaultValue = newDate;
        } else if (this.data && this.data.numberType) {
          // TODO pq les nombres sont sous formes x,xx et pas x.xx
          // Conversion string to number...
          const defaultValueNumber =
              defaultValue && defaultValue.replace ? parseFloat(defaultValue.replace(',', '.')) : defaultValue;
          if (isNaN(defaultValueNumber) || defaultValueNumber === null) {
            this.defaultValue = null;
          } else {
            this.defaultValue = defaultValueNumber;
          }
        }
      }

      if (this.defaultValue !== undefined && this.defaultValue !== null) {
        // on ne set les valeurs par défaut ssi il n'y a pas de valeur via url dans le process
        // this.updateStore(this.defaultValue);
        if (dataStored) {
          if (this.isDateField) {
            if (this.defaultValue) {
              this.defaultValue = moment(this.defaultValue).format('YYYY-MM-DDTHH:mm:ss.SSS');
              this.defaultValue =
                  this.defaultValue.substring(0, 26) + this.defaultValue.substring(27, this.defaultValue.length);
            }
          }
          // dataStored.data[this.data.columnName] = this.defaultValue;
        }
      }

      this.changeFieldValue(dataStored, true, [], true, this.defaultValue);

      sub.unsubscribe();
    });
  }

  // On parse la defaultValue et on la renvoie de manière asynchrone
  private parseDefaultValue(dataStored: DataStore) {
    const defaultValueToUse = this.data.fromRange ? this.data.defaultValue2 : this.data.defaultValue;
    if (!this.isInUniversalFilter) {
      // on set les valeurs du processus qui sont spécifiées dans l'url
      if (this.parentProcess && this.parentProcess.paramsMap.get(this.data.columnName)) {
        if (this.isDateField) {
          const dateValue = new Date();
          dateValue.setTime(this.parentProcess.paramsMap.get(this.data.columnName));
          this.updateStore(dateValue, [], true);
        } else {
          this.updateStore(this.parentProcess.paramsMap.get(this.data.columnName), [], true);
        }
        if (dataStored) {
          if (this.isDateField) {
            const dateValue = new Date();
            dateValue.setTime(this.parentProcess.paramsMap.get(this.data.columnName));
            this.defaultValue = moment(dateValue).format('YYYY-MM-DDTHH:mm:ss.SSS');
            this.defaultValue =
                this.defaultValue.substring(0, 26) + this.defaultValue.substring(27, this.defaultValue.length);
            dataStored.data[this.data.columnName] = this.defaultValue;
          }
        }
      } else {
        this.waitDefaultValueParsing(dataStored);
        // const dv$ = new Subject<any>();
        if (defaultValueToUse) {
          // si defaultValue on la traite
          if (defaultValueToUse.trim().toLowerCase().startsWith('@sql=')) {
            this.uiCreatorService
                .getDBSelect(
                    LogicEvaluator.replaceVariables(
                        defaultValueToUse.slice(5),
                        this.connectorService.getIupicsUserContext(),
                        this.getCurrentContext()
                    ),
                    [],
                    []
                )
                .subscribe((data) => {
                  if (data.length > 0) {
                    this.dv$.next(data[0][Object.keys(data[0])[0]]);
                  }
                });
          } else {
            const dvs = defaultValueToUse.split(';');
            let ok = false;
            let dv: any;
            for (let i = 0; i < dvs.length && !ok; i++) {
              let dvLogic = dvs[i];
              if (!this.isDateField && defaultValueToUse.indexOf('@#Date@') >= 0) {
                moment.locale(this.locale);
                dvLogic = dvLogic.replace(
                    /@#Date@/g,
                    moment(this.connectorService.getIupicsUserContext()['#Date']).format('L')
                );
              }
              dv = LogicEvaluator.replaceVariables(
                  dvLogic,
                  this.connectorService.getIupicsUserContext(),
                  this.getCurrentContext()
              );
              if (dv !== undefined && dv !== null) {
                ok = true;
              }
            }
            this.dv$.next(this.extractId(dv));
          }
        } else {
          let findMatch = null;
          if ((this.container && this.container.windowType === IupicsMenuType.FORM) || this.parentProcess) {
            // si on trouve des valeurs dans le contexte dans le cas d'un process ou d'une form
            const ctx = this.getCurrentContext();
            findMatch = this.extractId(ctx[this.data.columnName]);
          }
          if (findMatch === undefined || findMatch === null) {
            if (this.container) {
              // si on trouve des valeurs dans le contexte, on considere qu'on a des defaultValue
              findMatch = this.checkContext();
            } else if (
                this.container &&
                this.container.windowType === IupicsMenuType.FORM &&
                this.container.activeTab &&
                this.container.activeTab.ctx
            ) {
              // si on trouve des valeurs dans le contexte passé dans la notif
              findMatch = this.container.activeTab.ctx[this.data.columnName];
            }
          }

          if (findMatch === undefined || findMatch === null) {
            if (this.isSwitchField && !this.isTriState) {
              findMatch = 'N';
            }
            if (this.data.numberType && this.data.isDisplayed) {
              findMatch = 0;
            }
          }
          if (findMatch !== undefined && findMatch !== null) {
            this.dv$.next(findMatch);
          } else {
            this.updateUI();
          }
        }
      }
    }
  }

  checkContext(): any {
    let findMatch: any;

    const columnName = this.data.columnName;
    const windowId = this.dataStored ? this.dataStored.key.windowId : -1;
    const context = Object.assign(this.connectorService.getIupicsUserContext());
    const keys = Object.keys(context);
    let prefix = 'P';
    switch (this.fieldType) {
      case CompiereDataFieldType.FORM_ITEM:
        prefix = 'PF';
        break;
      case CompiereDataFieldType.PROCESS_PARA:
        prefix = 'PP';
        break;

      default:
        prefix = 'P';
        break;
    }
    if (keys.find((k) => k === prefix + windowId + '|' + columnName)) {
      findMatch = context[keys.find((k) => k === prefix + windowId + '|' + columnName)];
    } else if (keys.find((k) => k === prefix + '|' + columnName)) {
      findMatch = context[keys.find((k) => k === prefix + '|' + columnName)];
    } else if (keys.find((k) => k === '$' + columnName)) {
      findMatch = context[keys.find((k) => k === '$' + columnName)];
    } else if (keys.find((k) => k === '#' + columnName)) {
      findMatch = context[keys.find((k) => k === '#' + columnName)];
    }
    return findMatch;
  }

  /**
   * C'est la méthode de merge par champs
   * @param event MouseEvent
   * @param chosenData Les données choisies lors du conflit
   */
  chooseData(event: MouseEvent, chosenData: any, isLocalChoose: boolean) {
    event.preventDefault();
    event.stopPropagation();
    this.hasConflict = false;
    const tempData = {};
    tempData[this.data.columnName] = chosenData;
    this.store.syncDataChanges(this.dataStored, tempData);
    if (isLocalChoose) {
      this.store.copyRemoteWindowDataToOldStore(this.dataStored.key, this.data.columnName);
    } else {
      this.store.copyWindowDataToOldStore(this.dataStored);
    }
    delete this.editViewParent.conflictsResult.dataConflict[this.data.columnName];
  }

  changeFieldValue(
      dataStored: DataStore,
      fromOtherChange: boolean = false,
      calloutStack: string[] = [],
      fromDefault: boolean = false,
      defaultValue: any = null
  ) {
    // On ne veut pas que DocAction et DocStatus se déclenche vu que c'est retourné par /newRecord
    let shouldCallWs = fromDefault || !['DocAction', 'DocStatus'].includes(this.data.columnName);
    // Change field value
    const tmpValue = fromDefault ? defaultValue : dataStored.data[this.data.columnName];
    if (this.isDateField && !this.calendarConfig.todayMode) {
      if (tmpValue) {
        this.fieldValue = this.getDateValue(tmpValue);
      } else {
        this.fieldValue = null;
      }
    } else {
      if (
          this.isAutocompleteField &&
          dataStored.data[this.data.columnName] !== null &&
          dataStored.data[this.data.columnName] !== undefined &&
          typeof dataStored.data[this.data.columnName] === 'object' &&
          (this.fieldValue === dataStored.data[this.data.columnName].id ||
              (this.fieldValue && this.fieldValue.id === dataStored.data[this.data.columnName].id))
      ) {
        shouldCallWs = false;
      }
      if (this.multiple) {
        if (tmpValue !== null && tmpValue !== undefined) {
          if (Array.isArray(tmpValue)) {
            this.fieldValue = tmpValue;
          } else {
            if (this.fieldValue === null || this.fieldValue === undefined) {
              this.fieldValue = [tmpValue];
            } else {
              if (Array.isArray(this.fieldValue)) {
                const index = this.fieldValue.findIndex((v) => this.extractId(v) === this.extractId(tmpValue));
                if (index === -1) {
                  this.fieldValue = [...this.fieldValue, ...[tmpValue]];
                } else {
                  this.fieldValue[index] = tmpValue;
                }
              } else {
                if (this.extractId(this.fieldValue) !== this.extractId(tmpValue)) {
                  this.fieldValue = [...[this.fieldValue], ...[tmpValue]];
                }
              }
            }
          }
        }
      } else {
        // pour gérer les cas synchro filter et universalfilter sur les forms
        if (tmpValue && Array.isArray(tmpValue)) {
          this.fieldValue = tmpValue.length > 0 ? tmpValue[tmpValue.length - 1] : null;
        } else {
          this.fieldValue = tmpValue;
        }
      }
      if (this.isAutocompleteField && (this.fieldValue === null || this.fieldValue === undefined)) {
        // car la value doit être null pour afficher un champ vide
        this.fieldValue = null;
        shouldCallWs = false;
      }
    }
    if (this.isSwitchField) {
      if ((!this.isTriState && isNil(this.fieldValue)) || this.fieldValue === false) {
        this.fieldValue = 'N';
      }
    }
    if (this.data && this.data.numberType && typeof this.fieldValue !== 'number') {
      // TODO pq les nombres sont sous formes x,xx et pas x.xx
      // Conversion string to number...
      const defaultValueNumber =
          this.fieldValue && this.fieldValue.replace ? parseFloat(this.fieldValue.replace(',', '.')) : this.fieldValue;
      this.fieldValue = this._parseFloat(defaultValueNumber);
    }
    const valueToCompare = this.dataStored.data[this.data.columnName];
    const isDifferent = fromOtherChange
        ? this.compareValue(this.isDateField ? this['calendar']['value'] : this.fieldValue, valueToCompare)
        : false;
    if (this.isAutocompleteField) {
      if (typeof this.fieldValue !== 'object' || (this.multiple && typeof tmpValue !== 'object')) {
        if (this.changeFieldValueSub && this.changeFieldValueSub.unsubscribe) {
          this.changeFieldValueSub.unsubscribe();
        }
        const id = this.extractId(tmpValue);
        if (
            this.data.items &&
            this.data.items.length > 0 &&
            this.data.items.find((item) => item.id === id || parseInt(item.id, 10) === id) !== undefined
        ) {
          const foundItem = this.data.items.find((item) => item.id === id || parseInt(item.id, 10) === id);
          this.updateStore(foundItem, calloutStack, fromDefault);
        } else {
          if (shouldCallWs) {
            const entityId = this.fieldType === CompiereDataFieldType.FIELD ? this.data.fieldId : this.data.columnId;

            const validation = LogicEvaluator.replaceVariables(
                this.data.validationCode,
                this.connectorService.getIupicsUserContext(),
                this.getCurrentContext()
            );
            this.changeFieldValueSub = this.store
                .getAutocompleteDataById(
                    this.fieldType,
                    entityId,
                    id,
                    fromOtherChange ? validation : undefined // Change for redmine #112652
                )
                .subscribe((dataWs) => {
                  if (dataWs.length > 0 && dataWs.find((data) => data.id === id || parseInt(data.id, 10) === id)) {
                    const recordFound = dataWs.find((data) => data.id === id || parseInt(data.id, 10) === id);
                    this.updateStore(recordFound, calloutStack, fromDefault);
                  } else {
                    if (this.data.isMandatory && fromDefault) {
                      this.isSetDefaultValue = true;
                      this.setFieldMandatory();
                    } else {
                      this.updateStore(null, calloutStack, fromDefault);
                    }
                  }
                });
          }
        }
      } else if (isDifferent && fromDefault) {
        this.updateStore(this.fieldValue, calloutStack, fromDefault);
      }
    } else if (!this.isStandalone && this.dataStored) {
      if (isDifferent) {
        // emit change to other fields
        this.updateStore(this.isDateField ? this['calendar']['value'] : this.fieldValue, calloutStack, fromDefault);
      }
    }
    if (this.isStandalone) {
      this.updateUI();
    }
  }

  compareValue(value1: any, value2: any) {
    let isDifferent = false;
    if (this.multiple) {
      if (value1 instanceof Array && value2 instanceof Array) {
        if (value1.length !== value2.length) {
          // 2 arrays with different length
          isDifferent = true;
        } else if (
            value1.filter((val) => !value2.find((val2) => this.extractId(val) === this.extractId(val2))).length > 0
        ) {
          // 2 arrays with same length
          isDifferent = true;
        }
      } else if ((value1 && !value2) || (value2 && !value1)) {
        isDifferent = true;
      }
    } else {
      isDifferent = this.extractId(value1) !== this.extractId(value2);
    }
    if (this.isDateField) {
      isDifferent = this.compareDateValue(value1, value2);
    }
    return isDifferent;
  }
  compareDateValue(value1, value2) {
    let isDifferent = false;
    const value1Date = this.getDateValue(value1);
    const value2Date = this.getDateValue(value2);

    if (value2Date && value1Date) {
      isDifferent = !moment(value1Date).isSame(value2Date);
    } else {
      isDifferent = value1Date || value2Date;
    }
    return isDifferent;
  }
  getDateValue(value) {
    let tmpValue = value;
    if (value) {
      if (!(value instanceof Date)) {
        value = moment(value).format('YYYY-MM-DDTHH:mm:ss.SSS');
        value = value.substring(0, 26) + value.substring(27, value.length);
        tmpValue = new Date(value);
      }
      if (Object.prototype.toString.call(tmpValue) === '[object Date]' && !isNaN(tmpValue.getTime())) {
        if (this.data.needTime === false) {
          tmpValue.setHours(0);
          tmpValue.setMinutes(0);
          if (this.data.componentName === 'InputTimeUiComponent') {
            tmpValue.setDate(0);
            tmpValue.setFullYear(1969);
            tmpValue.setMonth(0);
          }
        }
        tmpValue.setSeconds(0);
        tmpValue.setMilliseconds(0);
      } else {
        tmpValue = null;
      }
    }
    return tmpValue;
  }
  resetAutocompleteFromZoom(id: any) {
    const idFormated = this.extractId(id);
    const entityId = this.fieldType === CompiereDataFieldType.FIELD ? this.data.fieldId : this.data.columnId;
    if (idFormated === null) {
      this.dataChange(null);
    } else {
      this.store
          .getAutocompleteDataById(
              this.fieldType,
              entityId,
              idFormated,
              LogicEvaluator.replaceVariables(
                  this.data.validationCode,
                  this.connectorService.getIupicsUserContext(),
                  this.getCurrentContext()
              )
          )
          .subscribe((dataWs) => {
            if (
                dataWs.length > 0 &&
                dataWs.find((data) => data.id === idFormated || parseInt(data.id, 10) === idFormated)
            ) {
              const recordFound = dataWs.find((data) => data.id === idFormated || parseInt(data.id, 10) === idFormated);
              this.dataChange(recordFound);
            }
          });
    }
  }

  onChildUpdate(event: IupicsEvent) {}
  onSiblingUpdate(event: IupicsEvent) {}
  onRemoveComponent(event: IupicsEvent) {}
  refreshZoomInfo() {}
  updateCurrency(data?: any) {}
  updateStore(value: any, calloutStack: string[] = [], fromDefaultValue = false) {
    const dataModified = {};
    let newValue = value;
    if (this.isDateField) {
      if (value) {
        newValue = moment(value).format('YYYY-MM-DDTHH:mm:ss.SSS');
        newValue = newValue.substring(0, 26) + newValue.substring(27, newValue.length);
      }
    }
    dataModified[this.data.columnName] = newValue;
    if (this.dataStored) {
      this.updateCurrentContext(newValue);
      if (fromDefaultValue) {
        this.isSetDefaultValue = true;
      }

      this.store.syncDataChanges(this.dataStored, dataModified, true, false, calloutStack, fromDefaultValue);
      if ((this.isAddressField || this.isAutocompleteField) && this.isZoom) {
        this.refreshZoomInfo();
      }

      if (this.isImageField) {
        this.displayImage = this.fieldValue ? true : false;
      }

      // Modifie l'url si le parent est un process
      if (this.parentProcess) {
        this.notifyUrlChange();
      }

      if (this.data.columnName === 'C_Currency_ID' && this.editViewParent) {
        this.editViewParent.updateCurrSymbol();
      }
      // UpdateStepper
      if (this.editViewParent && (this.data.columnName === 'DocStatus' || this.data.columnName === 'DocAction')) {
        if (this.dataStored.data['DocStatus'] && this.dataStored.data['DocAction']) {
          this.editViewParent.changingStepper.next(this.dataStored);
        }
      }
      if ((fromDefaultValue || !calloutStack || calloutStack.length === 0) && this.data.urlCallouts) {
        this.doCallout(newValue, calloutStack, fromDefaultValue);
      }
    }
  }
  /**
   * récupération du contexte complet du composant (fonctionne avec un système de cache, si on veut rebuild,il faut utiliser forceRefresh)
   * @param dataStore nouveau datastore à prendre en compte
   * @param overrideChild indiqué si on override les valeurs de l'enfant par celui du parent (true)
   * @param forceRefresh indique si on veut forcer la reconstruction du contexte (false)
   */
  getCurrentContext(dataStore?: DataStore, overrideChild = true, forceRefresh = false) {
    let editViewParent: any;
    let currentContext = null;
    if (this.parentTab && this.parentTab.editViewParent) {
      editViewParent = this.parentTab.editViewParent;
    } else if (this.editViewParent) {
      editViewParent = this.editViewParent;
    }
    /*ajout du tabId */
    let dataStored = dataStore ? dataStore : this.dataStored;
    if (overrideChild === false && !forceRefresh && dataStored && dataStored.currentContext) {
      currentContext = dataStored.currentContext;
    }
    if (dataStored && dataStored.data) {
      const newStore = {
        ...dataStored,
        data: {
          ...dataStored.data,
          Parent_Tab_ID: this.tabId ? this.tabId : this.parentTab ? this.parentTab.tabId : null,
        },
      } as DataStore;
      Object.setPrototypeOf(newStore, Object.getPrototypeOf(dataStored));
      dataStored = newStore;
    }

    // Suppression apiz_dataResult inutile dans le contexte
    if (dataStored && dataStored.data && dataStored.data.hasOwnProperty('apiz_dataResult')) {
      delete dataStored.data.apiz_dataResult;
    }
    if (dataStored && dataStored.data && dataStored.data.hasOwnProperty('apiz_ctxChanged')) {
      delete dataStored.data['apiz_ctxChanged'];
    }
    if (!this.dataStored) {
      currentContext = {};
    }
    // dans le cas d'un parent de type EditView
    if (!currentContext && (editViewParent || !this.container || !this.container.getCurrentContext)) {
      currentContext = EditViewUtils.getCurrentContext(
          editViewParent,
          dataStored,
          this.connectorService.getIupicsUserContext(),
          overrideChild
      );
      if (overrideChild === false) {
        this.dataStored.currentContext = currentContext;
      }
    }
    // dans le cas d'un parent de type Process ou Form
    if (!currentContext && this.container && this.container.getCurrentContext && !this.isInUniversalFilter) {
      const clonedData = cloneDeep(this.dataStored.data);
      currentContext = EditViewUtils.mergeCurrentDataDeepCopy(
          clonedData,
          this.container.getCurrentContext(),
          overrideChild
      );
      if (overrideChild === false) {
        this.dataStored.currentContext = currentContext;
      }
    }
    return currentContext;
  }

  ngAfterViewInit(): void {
    this.afterViewInitEmitter.emit();
    if (has(this.data, 'isDisplayed')) {
      this.elementRef.nativeElement.setAttribute('data-cy-ad-is-displayed', String(this.data.isDisplayed));
    }
    Global.endPerf(this, '');
  }
  updateCurrentContext(newValue: any) {
    if (this.dataStored && this.dataStored.currentContext) {
      this.dataStored.currentContext[this.data.columnName] = newValue
          ? newValue.id
              ? newValue.id
              : newValue
          : newValue;
    }
  }
  doCallout(value: any, calloutStack = [], fromDefault = false) {
    if ((fromDefault || !calloutStack || calloutStack.length === 0) && this.data.urlCallouts) {
      const currentContext = cloneDeep(this.getCurrentContext());
      let newValue = value;
      if (value && value.id !== undefined && value.id !== null) {
        newValue = value.id;
      }
      if (this.isDateField) {
        if (value) {
          const timeVal = moment(value);
          if (this.data.needTime === false) {
            timeVal.set('hour', 0);
            timeVal.set('minute', 0);
            if (this.data.componentName === 'InputTimeUiComponent') {
              timeVal.set('date', 0);
              timeVal.set('year', 1969);
              timeVal.set('month', 0);
            }
          }
          timeVal.set('second', 0);
          timeVal.set('millisecond', 0);
          newValue = timeVal.format('YYYY-MM-DDTHH:mm:ss.SSS');
          newValue = newValue.substring(0, 26) + newValue.substring(27, newValue.length);
        }
      }
      const valueBeforeCallout = currentContext[this.data.columnName];
      let oldValue = valueBeforeCallout;
      if (valueBeforeCallout && valueBeforeCallout.id !== undefined && valueBeforeCallout.id !== null) {
        oldValue = valueBeforeCallout.id;
      }
      if (oldValue === newValue) {
        oldValue = null;
      }
      if (this.isDateField) {
        if (valueBeforeCallout) {
          const timeVal = moment(valueBeforeCallout);
          if (this.data.needTime === false) {
            timeVal.set('hour', 0);
            timeVal.set('minute', 0);
            if (this.data.componentName === 'InputTimeUiComponent') {
              timeVal.set('date', 0);
              timeVal.set('year', 1969);
              timeVal.set('month', 0);
            }
          }
          timeVal.set('second', 0);
          timeVal.set('millisecond', 0);
          oldValue = timeVal.format('YYYY-MM-DDTHH:mm:ss.SSS');
          oldValue = oldValue.substring(0, 26) + oldValue.substring(27, oldValue.length);
        }
      }
      // alimenter le pending callout au changement d'un champ
      this.store.calloutData(
          this.data.urlCallouts,
          {
            columnName: this.data.columnName,
            newValue: newValue,
            windowCtx: currentContext,
            calloutStack: calloutStack,
            oldValue: oldValue,
          },
          this.dataStored,
          this
      );
    }
  }
  /**
   * Initialise le contexte et les variables dynamiques pour chaque logique (readonly,display,mandatory,validation)
   */
  initContextField() {
    this.updateLogicContext(LogicContextProperty.VALIDATION);
    this.updateLogicContext(LogicContextProperty.DEFAULT_VALUE);
    this.updateLogicContext(LogicContextProperty.READONLY);
    this.updateLogicContext(LogicContextProperty.MANDATORY);
    this.updateLogicContext(LogicContextProperty.DISPLAY);
  }
  /**
   * Permet de mettre à jour la readonly,display et la mandatory du datacontainer
   * @param dataChanged objet contenant les noms de colonnes modifiées et leur valeur associée
   */
  updateUI(dataChanged?: any) {
    if (!this.isInUniversalFilter) {
      this.updateDisplay(this.dataStored, dataChanged);
      this.setFieldMandatory();
      this.updateReadOnly(this.dataStored, dataChanged);
    }
  }
  /**
   * vérifie pour une logique choisie si cell-ci contient des variables dynamiques(@@)
   * @param property (  LogicContextProperty.MANDATORY,VALIDATION,READONLY,DISPLAY)
   */
  hasContextLogicVariable(property: LogicContextProperty) {
    return this.logicContextField[property]
        ? Object.keys(this.logicContextField[property]).length > 0
            ? true
            : false
        : false;
  }
  /**
   * Vérifie si il y'a eu des changements sur les variables contenues dans la logique
   * @param columns noms des colonnes modifiés
   * @param property (  LogicContextProperty.MANDATORY,VALIDATION,READONLY,DISPLAY)
   */
  verifyContextLogic(columns: string[], property: LogicContextProperty) {
    let changed = false;
    if (this.logicContextField[property]) {
      for (let i = 0; i < columns.length && !changed; i++) {
        const column = columns[i];
        // check changement des variables de la validation
        // 129904 ajout de la prise en compte de la mise à jour de #AD_ORG_ID
        let columnToUse = column;
        if (column === 'AD_Org_ID' && this.logicContextField[property].hasOwnProperty('#' + column)) {
          columnToUse = '#AD_Org_ID';
        }
        if (this.dataStored.data && this.logicContextField[property].hasOwnProperty(columnToUse)) {
          if (
              this.logicContextField[property][columnToUse] instanceof Object &&
              this.dataStored.data[column] instanceof Object
          ) {
            changed = this.logicContextField[property][columnToUse].id != this.dataStored.data[column].id;
          } else {
            changed =
                this.logicContextField[property][columnToUse] !=
                (this.dataStored.data[column] instanceof Object
                    ? this.dataStored.data[column].id
                    : this.dataStored.data[column]);
          }
        }
      }
      if (changed) {
        this.updateLogicContext(property);
      }
    }
    return changed;
  }
  /**
   * Initialise le contexte et les variables dynamiques de la logique choisie si elle existe
   * @param property (  LogicContextProperty.MANDATORY,VALIDATION,READONLY,DISPLAY)
   */
  updateLogicContext(property: LogicContextProperty) {
    if (this.data) {
      let logic = null;
      switch (property) {
        case LogicContextProperty.DISPLAY:
          logic = this.data.displayLogic;
          break;
        case LogicContextProperty.READONLY:
          logic = this.data.readOnlyLogic;
          break;
        case LogicContextProperty.MANDATORY:
          logic = this.data.mandatoryLogic;
          break;
        case LogicContextProperty.VALIDATION:
          logic = this.data.validationCode;
          break;
        case LogicContextProperty.DEFAULT_VALUE:
          logic = this.data.fromRange ? this.data.defaultValue2 : this.data.defaultValue;
          break;
        default:
          break;
      }
      if (logic) {
        this.logicContextField[property] = LogicEvaluator.getContextField(
            logic,
            this.dataStored ? this.connectorService.getIupicsUserContext() : null,
            this.dataStored ? this.getCurrentContext() : null
        );
      }
    }
  }
  /**
   * Mets à jour le logic context avec la dernière valeur calculée ou reçue.
   * @param property (  LogicContextProperty.MANDATORY,VALIDATION,READONLY,DISPLAY)
   * @param value any
   */
  updateCurrentLogicContextValue(property: LogicContextProperty, value: any) {
    if (this.logicContextField[property]) {
      this.logicContextField[property][this.data.columnName + 'Value'] = value;
    }
  }
  /**
   * Récupère la dernière valeur calculée ou reçue dans le logic context.
   * @param property (  LogicContextProperty.MANDATORY,VALIDATION,READONLY,DISPLAY)
   */
  getCurrentLogicContextValue(property: LogicContextProperty) {
    if (
        this.logicContextField[property] &&
        this.logicContextField[property][this.data.columnName + 'Value'] !== undefined
    ) {
      return this.logicContextField[property][this.data.columnName + 'Value'];
    } else {
      return undefined;
    }
  }
  initCurrency(dataStored: DataStore) {
    this.updateCurrency(dataStored.data);
  }
  extractId(valueToExtract: any): any {
    let value = cloneDeep(valueToExtract);
    if (value && value.id !== undefined) {
      value = value.id;
    }
    let id = value;
    if (
        this.isAutocompleteField &&
        (!this.data || !this.data.details || this.data.details.tableName !== 'AD_Ref_List')
    ) {
      if (value !== undefined && value !== null && /^[-+]?(\d+|Infinity)$/.test(value)) {
        id = parseInt(value, 10);
      }
    } else if (id && this?.data?.details?.tableName === 'AD_Ref_List') {
      id = id.replace(new RegExp("'", 'g'), '');
    }
    return id;
  }
}

export enum LogicContextProperty {
  MANDATORY = 'mandatory',
  VALIDATION = 'validation',
  READONLY = 'readonly',
  DISPLAY = 'display',
  DEFAULT_VALUE = 'defaultvalue',
}
export class LogicContext {
  mandatory: any;
  readonly: any;
  display: any;
  validation: any;
  defaultvalue: any;
}
export class AbstractDataContainerCallout {
  dataContainers: AbstractDataContainer[];
  constructor(dataContainers: AbstractDataContainer[]) {
    this.dataContainers = dataContainers;
  }

  getField(columnName: string): AbstractDataContainer {
    for (let i = 0; i < this.dataContainers.length; i++) {
      if (this.dataContainers[i].data.columnName === columnName) {
        return this.dataContainers[i];
      }
    }
    return null;
  }
}
