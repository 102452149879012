<div
        #editViewElement
        [ngClass]="cssClass"
        [style.height.%]="100"
        [style.width]="!sideComponentRef ? cssWidth : undefined"
        style="font-size: 16px"
        [attr.data-cy-editview-tablename]="editTabs[0]?.data.tableName"
        [attr.data-cy-editview-tablevel]="editTabs[0]?.data.tabLevel"
>
  @if (isZoomTarget) {
  <div
    class="iupics-blade-content-sidebar"
    [ngClass]="[GridViewVisibility === 'hidden' ? 'invisible' : 'visible']"
    [ngStyle]="{ visibility: GridViewVisibility }"
    >
      <div class="iupics-blade-content-sidebar-content visible">
        <div class="p-toolbar ui-widget-header ui-corner-all ui-helper-clearfix">
          <div class="ui-right" (click)="GridViewVisibility = 'hidden'">
            <button pButton type="button" icon="icon-close" class="p-button p-menu-bar-button p-button-icon-only"></button>
          </div>
        </div>
        <iu-grid-view-ui
          #gridViewUi
          (gridTabAfterViewInitEmitter)="onZoomTargetGridAfterViewInit($event)"
          (gridViewCellClicked)="gridViewCellClicked($event)"
          [container]="container"
          [cssClass]="'iupics-blade-content-first'"
          [data]="zoomTargetData[0].gridView.data"
          [DOMParentComponent]="container"
          [gridTabFilter]="filterZoomTarget"
          [hasUniversalFilter]="true"
          [isMenuDisplay]="true"
          [isZoomTargetGrid]="true"
          [tabId]="zoomTargetData[0].tabId"
          [windowType]="undefined"
        />
      </div>
    </div>
  }
  <!-- #region audit panel -->
  @if (showAuditsPanel) {
  <div class="audits-panel" (click)="showAuditsPanel = false">
    <div class="audits-panel-content" (click)="$event.preventDefault(); $event.stopPropagation()">
      <ng-template #vcrAudit />
    </div>
  </div>
  }
  <!-- #endregion -->
  <!-- #START CUSTO-SAMVAZ PrintEvent-->
  <iu-menu-bar-detail-ui
          (changeGridElementEvent)="changeGridElement($event)"
          (changeGridViewVisibility)="changeGridViewVisibility($event)"
          (closeEvent)="checkBeforeClose($event)"
          (copyEvent)="copyData($event)"
          (deleteEvent)="deleteData($event)"
          (emailEvent)="updateModalDisplay({ key: 'displayEmailEditor', value: true })"
          (exportDataEvent)="updateModalDisplay({ key: 'displayExportDataModal', value: true })"
          (joinFilesEvent)="updateModalDisplay({ key: 'displayJoinFilesPanel', value: true })"
          (newEvent)="openNew($event)"
          (noteEvent)="showNotes()"
          (printEvent)="updateModalDisplay({ key: 'displayPrintDataModal', value: true })"
          (refreshEvent)="refreshData(true, $event)"
          (saveEvent)="beforeSave($event)"
          (viewRecordChangeLog)="viewRecordChangeLog($event)"
          [activeTabID]="container?.activeTab?.id"
          [arrowsDisplayed]="linkedComponents && linkedComponents.length > 0 ? linkedComponents[0].children.length === 0 : false"
          [breadcrumb]="container.breadcrumbComponent"
          [changingMenuBar]="changingMenuBar"
          [isDeleteable]="isDeleteable"
          [isInsertRecord]="isInsertRecord"
          [isReadOnly]="isReadOnly"
          [isSplitView]="isSplitView"
          [isZoomEditView]="isZoomEditView"
          [isZoomTarget]="zoomTarget ? true : false"
          [linkedComponents]="linkedComponents"
          [nbUploadedFiles]="nbUploadedFiles"
          [noData]="noData"
          [processed]="
      editTabs[0] && editTabs[0].dataStored && editTabs[0].dataStored.data ? editTabs[0].dataStored.data.Processed : 'N'
    "
          [status]="editTabs[0] && editTabs[0].dataStored ? editTabs[0].dataStored.status : DatastoreStatus.SYNC"
          [tabId]="tabId"
          [viewRecordChangeLogLabel]="editTabs[0] ? editTabs[0]?.data.label : ('tabUi.loading' | translate)"
  />
  <!-- #END CUSTO-SAMVAZ -->
  @if (isLoading) {
  <p-progressBar [mode]="'indeterminate'" [value]="0" [showValue]="false" [styleClass]="'loading-progressbar'" />
  }

  <div class="compContainer">
    <div class="contentInline" [style.width]="cssWidth" style="font-size: 16px; word-break: break-all">
      <div class="iu-editview-info">
        <div class="tabManage">
          <ul class="tabNamesManager">
            <li>
              <a href="#" class="open-icon-title" (click)="$event.preventDefault()">
                <span>{{ 'editView.sections' | translate }}</span>
                <i class="icon-next-ancre" aria-hidden="true"></i>
              </a>
              <ul class="tab-list">
                @for (tab of editTabs; track i; let i = $index) {
                @if (tab.isDisplay !== 'none') {
                <li>
                  <a href="#" class="open-icon" (click)="$event.preventDefault(); goToAnchor($event, tab)">
                    <span>{{ tab.label }}</span>
                    <i class="icon-next-ancre" aria-hidden="true"></i>
                  </a>
                </li>
                }
                }
              </ul>
            </li>
          </ul>
        </div>
        <iu-menu-smartbuttons-ui class="iu-editview-smartbuttons" [tabId]="tabId" [windowId]="data.AD_Window_ID" />
      </div>
      @if (isStepperVisible) {
      @if (editTabs[0] && editTabs[0].data && editTabs[0].data.isDocumentTab) {
      <div #stepperDiv class="p-col-12 ui-stepper">
        <iu-stepper-ui
                (refreshEdit)="refreshEditViews(false)"
                [data]="editTabs[0].data.stepperData"
                [changingStepper]="changingStepper"
                [isReadOnly]="false"
                [isIndexNumber]="false"
                [preventPrevious]="true"
                [editTab]="editTabs[0]"
                [parent]="this"
        />
      </div>
      } @else if (isStepperEmpty) {
      <div class="p-col-12 ui-stepper">
        <iu-stepper-ui [isReadOnly]="true" />
      </div>
      }
      }

      @if (conflictsResult.refreshAuto) {
      <iu-banner type="success">
      {{ 'editView.refreshAutoMsg' | translate }}
      </iu-banner>
      } @else if (conflictsResult.mustRefresh) {
      <iu-banner type="warning" icon="icon-refresh3x">
      {{ 'editView.mustRefreshMsg' | translate }}
      </iu-banner>
      }
      @if (conflictsResult.hasConflicts) {
      <iu-banner type="error">
      {{ 'editView.hasConflictsMsg' | translate }}
      <a style="text-decoration: underline; cursor: pointer" (click)="wantToMergeConflicts = true">
      {{ 'editView.verify' | translate }}
      </a>
      {{ 'editView.andSave' | translate }} <br />{{ nbConflicts }} {{ 'editView.remainingConflict' | translate }}
      </iu-banner>
      }
      <p-scrollPanel
              [style]="{
          width: '100%',
          height: isStepperVisible ? 'calc(100% - ' + stepperElt?.nativeElement?.offsetHeight + 'px)' : '100%'
        }"
      >
        <div #gridUi id="{{ scrollPanelid }}" class="p-col contentInlineScrollPanel">
          <ng-template iuDynamicContainer />
        </div>
      </p-scrollPanel>
    </div>
    <div class="contentInline2">
      @if (additionalInfoWidthExpanded) {
      <article #article class="accordion" [style.height.%]="100" style="position: relative">
        <section
          id="acc1"
          [ngClass]="[!isAdditionalOpened ? 'additionalHidden' : '']"
          [style.height.%]="100"
          [style.width]="additionalInfoWidth"
        >
          <h2>
            <a #openButton (click)="isAdditionalOpened ? minimize() : resize()">
              <i class="icon-up" aria-hidden="true"></i>
              <span>{{ 'editView.additionalInformation' | translate }}</span>
              </a>
            </h2>
            <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
              <span><ng-container #additionalInfoVcr /></span>
            </p-scrollPanel>
          </section>
        </article>
      }
    </div>
    <div #sideBladeContainer class="side-blade-wraper" [attr.data-expanded]="sideComponentRef != null">
      @if (sideComponentRef) {
      <div class="side-blade-header-content">
        <div class="side-blade-title">
          <i [class]="sideBladeIconClass" aria-hidden="true"></i>
          <span class="title" pTooltip="{{ sideBladeTitle }}">{{ sideBladeTitle }}</span>
          </div>
          <div class="side-blade-buttons">
            <iu-prime-speedDial
              [model]="sideBladeButtons"
              [radius]="50"
              direction="down"
              showIcon="icon-menu-bullets"
              hideIcon="fa fa-times"
              buttonClassName="p-button-outlined"
            />
          </div>
        </div>
      }
      <div class="side-blade-content" [ngClass]="[sideComponentRef ? 'side-blade-content-shown' : '']">
        <ng-container #sideBladeVcr />
      </div>
      @if (sideComponentRef) {
      <div class="resizable-handle" (touchstart)="startResizeSideBlade($event)" (mousedown)="startResizeSideBlade($event)">
        <i class="fa fa-caret-right"></i>
      </div>
      }
    </div>
  </div>
</div>

@if (wantToMergeConflicts) {
<div class="modal-background">
<iu-mergetool [conflictsResult]="conflictsResult" [parentEditView]="this" (mergeEmitter)="onMerge($event)" />
</div>
}

<p-sidebar
        #sideBar
        [(visible)]="isSidebarOpen"
        [dismissible]="false"
        [baseZIndex]="0"
        position="right"
        styleClass="p-sidebar-md "
        (onHide)="closeSideBarAndBlade()"
>
  <ng-container #sideBarVcr />
</p-sidebar>

@if (displayJoinFilesPanel) {
<iu-modal-ui
[title]="'joinFiles.title'"
[hasCloseBtn]="true"
[contentType]="ModalContentType.JOINFILE"
(closeModalEmitter)="updateModalDisplay({ key: 'displayJoinFilesPanel', value: false })"
>
<iu-join-file-ui
[dsKey]="currentDataStoreKey"
[adTable_ID]="table_id"
[adTab_ID]="tabId"
[(nbUploadedFiles)]="nbUploadedFiles"
[data]="data"
(openFileOnSideEmitter)="showPreview($event)"
/>
</iu-modal-ui>
}
@if (displayEmailEditor) {
<iu-modal-ui [title]="'email.title'" (closeModalEmitter)="updateModalDisplay({ key: 'displayEmailEditor', value: false })">
<iu-email-editor-ui
[parentEditView]="this"
(cancelEmailEmitter)="updateModalDisplay({ key: 'displayEmailEditor', value: false })"
/>
</iu-modal-ui>
}
@if (displayExportDataModal) {
<iu-modal-ui
#exportDataModal
[title]="'exportData.title'"
(closeModalEmitter)="updateModalDisplay({ key: 'displayExportDataModal', value: false })"
[angularStyle]="{ 'iu-modal-body': { overflow: 'unset' } }"
>
<iu-export-data-ui
[tableId]="editTabs[0].data.AD_Table_ID"
[tabId]="tabId"
[dsKey]="currentDataStoreKey"
(cancelExportDataEmitter)="updateModalDisplay({ key: 'displayExportDataModal', value: false })"
[sourceModal]="exportDataModal"
(afterExecute)="updateNbFileAttached()"
/>
</iu-modal-ui>
}

<!-- #START CUSTO-SAMVAZ -->
@if (displayPrintDataModal) {
<iu-modal-ui
#printDataModal
[title]="'print.title'"
(closeModalEmitter)="updateModalDisplay({ key: 'displayPrintDataModal', value: false })"
[angularStyle]="{ 'iu-modal-body': { overflow: 'unset' } }"
>
<print-ui
[dsKey]="currentDataStoreKey"
[uuid]="this.uuid"
(cancelPrintDataEmitter)="updateModalDisplay({ key: 'displayPrintDataModal', value: false })"
[sourceModal]="printDataModal"
[printCtx]="editTabs[0].dataStored.data"
[editViewParent]="this"
></print-ui>
</iu-modal-ui>
}
<!-- #END CUSTO-SAMVAZ -->

@if (displayProcessUI) {
<iu-modal-ui
(closeModalEmitter)="updateModalDisplay({ key: 'displayProcessUI', value: false })"
[hasCloseBtn]="true"
[angularStyle]="{ 'iu-modal-body': { padding: 0 } }"
[contentType]="ModalContentType.PROCESS"
>
<iu-process-ui
(closeModalEmitter)="updateModalDisplay({ key: 'displayProcessUI', value: false })"
[windowId]="processId"
[parentComponent]="this"
[sourceComponentData]="displayProcessUI_sourceComponentData"
[isModal]="true"
/>
</iu-modal-ui>
}
<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
          #specificModal
          [title]="specificWindowTitle"
          [isModalDisplay]="displayFormUI"
          [angularStyle]="customFormModalBodyCss"
          [modalClass]="modalClass"
          [contentType]="ModalContentType.FORM"
          (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })"
  >
    <ng-template #vcrSpecific />
  </iu-modal-ui>
</div>
