import { NgClass } from '@angular/common';
import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { DataStore } from '@compiere-ws/models/compiere-data-json';
import { POTable } from '@compiere-ws/models/po.models';
import { ButtonLevel } from '@iupics-components/models/custom-types';
import EditViewUiComponent from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsEvent, IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { WorkspaceService } from '@web-desktop/components/workspace/components/workspace-ui/workspace.service';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'iu-button-ui',
  templateUrl: './button-ui.component.html',
  styleUrls: ['./button-ui.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [TooltipModule, ButtonModule, NgClass],
})
export default class ButtonUiComponent extends AbstractDataContainer implements OnInit, OnDestroy {
  #messageManager = inject(MessageManagerService);
  readonly #workspaceService = inject(WorkspaceService);

  @ViewChild('btn', { read: ElementRef, static: true }) btnElt: ElementRef<HTMLButtonElement>;
  @Input() icon = '';
  @Input() btnType: ButtonLevel;
  private listener: Function;

  itemData: DynamicComponent;

  private tableName: string;
  private isSOTrx: boolean;
  @Input()
  channelId;
  @Input()
  columnName: string;

  @HostBinding('class.is-field') isFieldClass = false;

  ngOnInit() {
    super.ngOnInit();
    this.cssGrid = this.cssClass;
    if (this.fieldValue instanceof Object && this.fieldValue.displayValue) {
      this.label = this.fieldValue.displayValue;
    }
    // Ajout des events sur le bouton
    if (this.itemData) {
      let parent: any = <EditViewUiComponent>(
          this.container.DOMChildrenComponent.find(
              (DOMChild: any) => DOMChild instanceof EditViewUiComponent && DOMChild.tabId === this.data.tabId
          )
      );
      parent = parent ?? this.editViewParent ?? this.container;
      if (this.itemData.processId) {
        this.data['processId'] = this.itemData.processId;
        this.listener = this.renderer.listen(this.btnElt.nativeElement, 'click', ($event: MouseEvent) => {
          $event.stopPropagation();
          parent.updateModalDisplay(
              { key: 'displayProcessUI', value: true, sourceComponent: this },
              { key: 'processId', value: this.data['processId'] }
          );
        });
      } else if (this.itemData.formId) {
        //TODO à commenter si on ne veut pas charger les données d'une form pour affichage instantanné au clic.
        if (!Global.isMobile()) {
          this.subscriptions.push(this.uiCreatorService.getSpecificWindow(this.itemData.formId).subscribe());
        }
        this.data['formId'] = this.itemData.formId;
        this.listener = this.renderer.listen(this.btnElt.nativeElement, 'click', ($event: MouseEvent) => {
          $event.stopPropagation();
          parent.updateModalDisplay(
              { key: 'displayFormUI', value: true, sourceComponent: this },
              { key: 'formId', value: this.data['formId'] }
          );
        });
      }
    }
    this.isFieldClass = this.editViewParent ? true : false;

    if(this.data && this.data.marginTop != 0){
      this.elementRef.nativeElement.setAttribute('style', 'margin-top: '+this.data.marginTop+'px;');
    }

  }

  onChildUpdate(event: IupicsEvent) {}
  onSiblingUpdate(event: IupicsEvent) {}
  onRemoveComponent(event: IupicsEvent) {}

  private initZoom() {
    if (this.dataStored.data.AD_Table_ID !== undefined && this.dataStored.data.AD_Table_ID !== null) {
      this.subscriptions.push(
          this.po.get<POTable>('AD_Table', this.dataStored.data.AD_Table_ID.id).subscribe((table) => {
            this.tableName = table.TableName;
            if (this.dataStored.data.IsSOTrx !== undefined) {
              this.isSOTrx = this.dataStored.data.IsSOTrx === 'Y';
            } else {
              this.isSOTrx = undefined;
            }
            if (this.listener !== undefined) {
              this.listener();
              this.listener = undefined;
            }

            this.listener = this.renderer.listen(this.btnElt.nativeElement, 'click', ($event: MouseEvent) => {
              $event.stopPropagation();
              this.subscriptions.push(
                  this.uiCreatorService
                      .zoomAcross(this.tableName, this.tableName + '_ID', this.dataStored.data.Record_ID, this.isSOTrx)
                      .subscribe({
                        next: (dataWs) => {
                          if (dataWs && dataWs.length > 0) {
                            this.zoomInfo = {
                              windowId: dataWs[0].Window_ID,
                              dataUUID: dataWs[dataWs.length - 1]['Record_ID'],
                              record_id: this.dataStored.data.Record_ID,
                              children: dataWs.length > 1 ? dataWs.splice(0, dataWs.length - 1) : null,
                            };
                            if (this.data.isParam) {
                              this.isZoom = false;
                              this.#workspaceService.openTargetSearchEmt.emit({
                                zoomInfo: this.zoomInfo,
                                cat: { id: parseInt(dataWs[0].Window_ID, 10) },
                                source: { id: this.dataStored.data.Record_ID !== -1 ? dataWs[0].Record_ID : 'newRecord' },
                              });
                            } else {
                              const windowId = parseInt(dataWs[dataWs.length - 1]['Window_ID'], 10);
                              this.subscriptions.push(
                                  this.uiCreatorService.getWindow(windowId).subscribe((tabUI) => {
                                    const item: DynamicComponent = {
                                      container: this.container,
                                      DOMParentComponent: this.container,
                                      linkedComponents: [this],
                                      component: 'EditViewUiComponent',
                                      cssClass: 'iupics-blade-content',
                                      isCssOnComponent: false,
                                      tabId: tabUI.tabId,
                                      windowId: windowId,
                                      zoomInfo: this.zoomInfo,
                                    };
                                    this.componentEmitter.emit({
                                      type: IupicsTypeEvent.showEditView,
                                      item: item,
                                    });
                                  })
                              );
                            }
                          }
                        },
                        error: (err) => {
                          this.isZoom = false;
                        },
                      })
              );
            });
          })
      );
    } else {
      this.listener = this.renderer.listen(this.btnElt.nativeElement, 'click', ($event: MouseEvent) => {
        this.#messageManager.newMessage(new IupicsMessage('Error', 'No record id linked'));
      });
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.listener !== undefined) {
      this.listener();
    }
  }

  changeFieldValue(dataStored: DataStore, fromOtherChange: boolean = false, calloutStack: string[] = []) {
    super.changeFieldValue(dataStored, fromOtherChange, calloutStack);

    if (this.fieldValue instanceof Object && this.fieldValue.displayValue) {
      this.label = this.fieldValue.displayValue;
    }
    if (this.data.columnName === 'Record_ID') {
      this.initZoom();
    }
  }
}
