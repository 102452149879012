//#START CUSTO-SAMVAZ
import { Component, inject } from '@angular/core';
import { CompiereDataGridFilterType, CompiereDataGridType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { CellEditorComp, CellEditorParams } from '@iupics/apiz-grid';
import { WorkspaceService } from '@web-desktop/components/workspace/components/workspace-ui/workspace.service';
import { has } from 'lodash';
import { Observable, of, zip } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-zoom-cell-renderer',
  template: ` <a class="apiz-ag-cell apiz-ag-zoom-cell" href="#" (click)="openZoom($event)">{{ params?.value || '' }}</a> `,
  styles: [
    `
      a.apiz-ag-zoom-cell {
        text-decoration: underline;
        color: blue;
      }
    `
  ]
})
export class ZoomCellRendererComponent implements CellEditorComp {
  params: any;
  label: string;
  templates: any;
  fieldValue = null;


  protected readonly workspaceService = inject(WorkspaceService);

  constructor(private uiCreatorService: UICreatorService, private store: DataStoreService) {}
  gridInit(params: CellEditorParams<any, any>): void {
    this.params = params;
    this.label = this.params.label || null;
    this.templates = this.params.templates;

    if (params.value !== undefined) {
      this.fieldValue = params.value;
    }
  }
  getValue() {
    return this.fieldValue;
  }


  refresh(params: any): boolean {
    return true;
  }

  openZoom(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    zip(this.getIsSOTrx(), this.getTableName())
        .pipe(
            switchMap(([IsSOTrx, tableName]) =>
                this.uiCreatorService.zoomAcross(tableName, `${tableName}_ID`, this.params.data.Record_ID, IsSOTrx))
        )
        .subscribe((dataWs: any[]) => {
          // après avoir récupéré les infos nécessaire au zoom on lance l'ouverture de la fenêtre
          if (dataWs && dataWs.length) {
            const zoomInfo = {
              windowId: dataWs[0].Window_ID,
              dataUUID: dataWs[dataWs.length - 1].Record_ID,
              record_id: this.params.data.Record_ID,
              children: dataWs.length > 1 ? dataWs.splice(0, dataWs.length - 1) : null
            };

            this.workspaceService.openTargetSearchEmt.emit({
              zoomInfo,
              cat: { id: parseInt(dataWs[0].Window_ID, 10) },
              source: { id: this.params.data.Record_ID !== -1 ? dataWs[0].Record_ID : 'newRecord' }
            });
          } else {
            throw new IupicsMessage('Attention', `Pas de zoom disponible pour la table ${this.params.data.AD_Table_ID}`, 'warning');
          }
        });
  }

  private getIsSOTrx(): Observable<boolean> {
    // on récupère le contexte trx
    let IsSOTrx = false;
    if (has(this.params.data, 'IsSOTrx')) {
      IsSOTrx = this.params.data.IsSOTrx === 'Y';
    } else if (has(this.params.data, 'C_DocType_ID')) {
      const request: DataStoreRequest = {
        windowId: -1,
        parent_constraint: undefined,
        compiereRequest: {
          windowType: CompiereDataGridType.TABLE,
          tableName: 'C_DocType',
          headerCols: [
            {
              displayName: 'IsSOTrx',
              field: 'IsSOTrx',
              id: 'IsSOTrx'
            }
          ],
          filterModel: {
            C_DocType_ID: {
              filterType: CompiereDataGridFilterType.SET,
              operators: [OperatorFilterType.EQUALS],
              values: [[this.params.data.C_DocType_ID]]
            }
          }
        }
      };
      return this.store
          .getDataGrid(request, true)
          .pipe(map((response) => (response && response.data?.length ? response.data[0]['IsSOTrx'] === 'Y' : false)));
    } else if (has(this.params, 'IsSOTrx')) {
      if (typeof this.params.IsSOTrx === 'function') {
        IsSOTrx = this.params.IsSOTrx(this.params);
      } else if (typeof this.params.IsSOTrx === 'string') {
        IsSOTrx = this.params.IsSOTrx === 'Y';
      } else if (typeof this.params.IsSOTrx === 'boolean') {
        IsSOTrx = this.params.IsSOTrx;
      }
    } else {
      IsSOTrx = false;
    }
    return of(IsSOTrx);
  }

  private getTableName(): Observable<string> {
    // on récupère le nom de la table qui est nécessaire pour faire le zoom
    if (has(this.params.data, 'TableName')) {
      return of(this.params.data.TableName);
    }
    const request: DataStoreRequest = {
      windowId: -1,
      parent_constraint: undefined,
      compiereRequest: {
        windowType: CompiereDataGridType.TABLE,
        tableName: 'AD_Table',
        headerCols: [
          {
            displayName: 'TableName',
            field: 'TableName',
            id: 'TableName'
          }
        ],
        filterModel: {
          AD_Table_ID: {
            filterType: CompiereDataGridFilterType.SET,
            operators: [OperatorFilterType.EQUALS],
            values: [[this.params.data.AD_Table_ID]]
          }
        }
      }
    };
    return this.store
        .getDataGrid(request, true)
        .pipe(map((response) => (response && response.data?.length ? response.data[0]['TableName'] : '')));
  }
}
