import { Injectable } from '@angular/core';
import { CompiereLanguage } from '@compiere-ws/models/compiere-language-json';
import { CompiereLanguageService } from '@compiere-ws/services/compiere-language/compiere-language.service';
import { CacheManagerService } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import {
  IupicsCookieService,
  LocalStorageIupics,
} from '@iupics-manager/managers/security-manager/cookies/iupics-cookie.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { cloneDeep } from 'lodash';
import { PrimeNGConfig } from 'primeng/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageSelectionService {
  private languages_isoCode: string[] = [];
  private readonly languageMapping = new Map([
    ['fr', 'fr_FR'],
    ['de', 'de-CH'],
    ['en', 'en_US'],
  ]);


  constructor(
    private cookieService: IupicsCookieService,
    private connectorService: SecurityManagerService,
    private languageService: CompiereLanguageService,
    private translate: TranslateService,
    private cacheService: CacheManagerService,
    private pNGConfig: PrimeNGConfig
  ) {
    this.languages_isoCode = environment.available_languages;
  }

  getLanguages(): Observable<CompiereLanguage[]> {
    return this.languageService.getSystemLanguage();
  }

  initLanguages(language: string): CompiereLanguage {
    this.translate.addLangs(this.languages_isoCode);

    // 1. Utilisation de la langue par défaut si celle du browser n'est pas supportée
    let defaultLanguage: CompiereLanguage = {
      id: environment.default_language,
      displayValue: environment.default_language,
      iso_code: environment.default_language,
    };

    // 2. Utilisation de la langue du navigateur
    const browserLanguage = navigator.language;
    const browserISOCode = this.languageMapping.has(browserLanguage)
      ? this.languageMapping.get(browserLanguage)
      : browserLanguage.replace('-', '_');

    if (this.translate.getLangs().includes(browserISOCode)) {
      defaultLanguage = {
        id: browserISOCode,
        displayValue: browserISOCode,
        iso_code: browserISOCode,
      };
    }

    // 3. Utilisation de la langue passé en paramètre
    if (this.translate.getLangs().includes(language)) {
      defaultLanguage = {
        id: language,
        displayValue: language,
        iso_code: language,
      };
    }

    this.translate.setDefaultLang(defaultLanguage.iso_code);
    this.changeLanguage(defaultLanguage);
    this.connectorService.setIupicsDefaultLanguage(defaultLanguage);
    return defaultLanguage;
  }

  changeLanguage(language: CompiereLanguage): boolean {
    this.translate.use(language.iso_code);
    this.translate.get('calendar').subscribe((res) => {
      this.pNGConfig.setTranslation(res);
    });
    this.connectorService.setIupicsDefaultLanguage(language);
    document.documentElement.lang = language.iso_code.split('_').shift();
    this.cacheService.resetCachingData();
    return true;
  }

  getCurrentLanguage(): CompiereLanguage {
    if (this.cookieService.checkLS(LocalStorageIupics.default_language)) {
      return JSON.parse(this.cookieService.getDecryptedLocalStorage(LocalStorageIupics.default_language));
    } else if (this.connectorService.getIupicsDefaultLanguage()) {
      return cloneDeep(this.connectorService.getIupicsDefaultLanguage());
    } else if (this.connectorService.getIupicsUserAccount().default_language) {
      return cloneDeep(this.connectorService.getIupicsUserAccount().default_language);
    }
    return undefined;
  }
}
